import React, {Fragment} from 'react';

const CaseStudyBrief = props => {
  return (
    <div>
      <div>
        <h1>{props.services}</h1>
        <h3>{props.brand}</h3>
        <p>{props.description}</p>
        <div className="container-case-study-intro-details">
            <div>
                <h1>{props.section1Title}</h1>
                <h5>{props.section1Text}</h5>
            </div>
            <div>
              <h1>{props.section2Title}</h1>
                <h5>{props.section2Text}</h5>
            </div>
            <div>
              <h1>{props.section3Title}</h1>
                <h5>{props.section3Text}</h5>
            </div>
            <div>
              <h1>{props.section4Title}</h1>
                <h5>{props.section4Text}</h5>
            </div>
        </div>
        <button>— View case study</button>
      </div>
      <div>
          <img />
          <img />
          <img />
      </div>
    </div>
  )
}

export default CaseStudyBrief;
