import React from 'react';

const Contact = () => {
  return (
    <div className="container-contact" id="contact">
      <h1>Reach Out</h1>
      <div data-tf-live="01HXEM4WBA7JC43Q90XHZRBY3A" />
    </div>
  );
}

export default Contact;
