import React, { Fragment } from 'react';
import CaseStudyBrief from '../components/CaseStudyBrief.js';
import Contact from '../components/Contact.js';

const Intro = () => {
  return (
    <div className="container-intro">
      <div>
        <h1>Hello, my name is Patricia Goh</h1>
        <p>A Vancouver-based Engineering Manager with a front-end & design forté. I work in the intersection between technology, business, and art. This is where I experiment and showcase some of the work that I enjoyed building.</p>
        <h5>Currently on a sabbatical from the software industry. I now spend my days in full creative mode building businesses.</h5>
      </div>
      <img src="/assets/about.jpeg" />
    </div>
  )
}

const Brand1 = () => {
  return (
    <CaseStudyBrief 
      services="Development • Design • Copywriting"
      brand="Brand 1"
      description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla lacinia vestibulum sapien. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut lacinia leo. In id hendrerit sem. In quis magna dolor. Praesent eu ex convallis sem vulputate."
      section1Title=""
      section1Text=""
      section2Title=""
      section2Text=""
      section3Title=""
      section3Text=""
      section4Title=""
      section4Text=""
    />
  )
}

const Brand2 = () => {
  return (
    <CaseStudyBrief 
      services="Development • Design • Copywriting"
      brand="Brand 2"
      description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla lacinia vestibulum sapien. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut lacinia leo. In id hendrerit sem. In quis magna dolor. Praesent eu ex convallis sem vulputate."
      section1Title=""
      section1Text=""
      section2Title=""
      section2Text=""
      section3Title=""
      section3Text=""
      section4Title=""
      section4Text=""
    />
  )
}

const Home = () => {
  return (
    <Fragment>
      <Intro />
      {/* <div className="container-case-study-intro">
        <Brand1 />
      </div> */}
      <Contact />
    </Fragment>
  );
}

export default Home;
